import React from "react";
import { Slide } from "./Slide";

export function Find() {
  return <Slide
    icon="./carousel/find-icon"
    image="./carousel/find-image"
    title="Bul"
    detail="Veri tabanındaki yüzlerce metin içinde aradığınız kelimenin geçtiği metinleri inceleyin; sonuçları filtreleyin, sıralayın."
  />;
}