import { DataSource } from ".";
import { list, create, read, update, del } from "../api/blacklists";
import { Blacklist } from "../models/Blacklist";
export const BlacklistsDS: DataSource<Blacklist> = {
  list,
  create,
  read,
  update,
  del,
};

export default BlacklistsDS;
