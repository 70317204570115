import React from "react";

import { HeaderButton } from "./HeaderButton";

import { PoweroffOutlined } from "@ant-design/icons";

export const LogoutButton = React.memo(
  (): JSX.Element => (
    <HeaderButton
      tooltip="Çıkış"
      type="primary"
      icon={<PoweroffOutlined />}
      to={{
        pathname: "/login",
        state: { logout: true },
      }}
    />
  ),
);
