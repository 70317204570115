import { DataSource } from "./index";
import { list, create, read, update, del } from "../api/issues";
import { Issue } from "../models/Issue";
export const IssuesDS: DataSource<Issue> = {
  list,
  create,
  read,
  update,
  del,
};

export default IssuesDS;
