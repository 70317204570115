const required = {
  required: true,
  message: "Alan zorunludur.",
};

const len = (val: number) => ({
  len: val,
  message: `${val} karakter olmalıdır.`,
});
const min = (val: number) => ({
  min: val,
  message: `En az ${val} karakter uzunluğunda olabilir.`,
});
const max = (val: number) => ({
  max: val,
  message: `En fazla ${val} karakter olabilir.`,
});
const minMax = (minVal: number, maxVal: number) => ({
  min: minVal,
  max: maxVal,
  message: `En az ${minVal} en fazla ${maxVal} karakter uzunluğunda olabilir.`,
});
const type = (val: string) => ({
  type: val,
});
const pattern = (val: string, message: string) => ({
  pattern: new RegExp(val),
});

export default {
  required,
  len,
  min,
  max,
  minMax,
  type,
  pattern,
};
