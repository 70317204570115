import React from "react";
import { Layout } from "antd";
import { HeaderButton } from "./header/HeaderButton";
import SideMenu from "./SideMenu";
import { Menu as MenuModel } from "../../data/models/Menu";
import { tree as menuTree } from "../../data/api/menus";
import { NotificationsButton } from "./header/notifications/NotificationsButton";
import { haveToken } from "../../utils/cookies";
import { MenuTrigger } from "./header/MenuTrigger";
import { Breadcrumb } from "./header/Breadcrumb";
import { LogoutButton } from "./header/LogoutButton";
import { Header } from "./header/Header";
import { Content } from "./Content";

import { QuestionOutlined } from "@ant-design/icons";

import { MainRouter, calculateRoutes } from "./Main.router";
import { isMobile } from "../../utils/styles";
import { User } from "../../data";
import { profile } from "../../data/api/profile";
import { ProfileButton } from "./header/ProfileButton";
const { Sider } = Layout;

interface State {
  collapsed: boolean;
  menu: MenuModel[];
}

// get props from navigator
function Main(props: any) {
  const [collapsed, setCollapsed] = React.useState(false);
  const [menu, setMenu] = React.useState<MenuModel[]>([]);
  const [user, setUser] = React.useState<User>();

  const toggle = React.useCallback(() => {
    localStorage.setItem("main.menu.collapsed", (!collapsed).toString());
    setCollapsed(!collapsed);
  }, [collapsed]);

  const onMenuSelect = React.useCallback(
    (item: any) => {
      window.scrollTo(0, 0);
      props.history.push(item.key);
    },
    [props.history],
  );

  React.useLayoutEffect(() => {
    if (!haveToken()) {
      window.location.hash = "/login";
    }
  }, []);

  React.useEffect(() => {
    const collapsed =
      isMobile() || localStorage.getItem("main.menu.collapsed") === "true";
    setCollapsed(collapsed);
    if (haveToken()) {
      menuTree(undefined, true)
        .then((resp) => {
          if (resp.Children) {
            calculateRoutes(resp.Children).then(() => {
              if (resp.Children) {
                setMenu(resp.Children);
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
      profile().then((user: User) => {
        setUser(user);
      });
    }
  }, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed} theme="dark">
        <div>
          <a href="/#/">
            {collapsed ? (
              <img
                style={{ height: 53, margin: 10, marginLeft: 10 }}
                src="./tdk.png"
                alt="e-logo"
              />
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img
                  style={{
                    height: 53,
                    marginTop: 10,
                    marginLeft: 10,
                    marginRight: 8,
                  }}
                  src="./tdk.png"
                  alt="e-logo"
                />
                <p
                  style={{
                    lineHeight: "70px",
                    textAlign: "center",
                    verticalAlign: "middle",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Metin Veri Tabanı
                </p>
              </div>
            )}
          </a>
        </div>

        <SideMenu
          data={menu}
          onSelect={onMenuSelect}
          selectedKeys={[props.location.pathname]}
          path={props.location.pathname}
          collapsed={collapsed}
        />
      </Sider>
      <Layout style={{ backgroundImage: "url(./tile2.png)" }}>
        <Header>
          <MenuTrigger collapsed={collapsed} onClick={toggle} />
          <Breadcrumb menu={menu} path={props.location.pathname} />
          <LogoutButton />
          <HeaderButton
            tooltip="Yardım"
            type="default"
            icon={<QuestionOutlined />}
            to="/faqs"
          />
          <NotificationsButton />
          <ProfileButton name={user?.NameSurname}/>
        </Header>

        <Content>
          <MainRouter path={menu.length && props.location.pathname} />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Main;
