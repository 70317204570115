import React from "react";
import { Slide } from "./Slide";

export function Preview() {
  return <Slide
    icon="./carousel/preview-icon"
    image="./carousel/preview-image"
    title="Görüntüle"
    detail="Ayrıntılı incelemek istediğiniz sonucu, ilgili eserin sayfası üzerinden görüntüleyin."
  />;
}