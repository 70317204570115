import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Faq extends BaseModel {
  ViewOrder: number;
  Subject: string;
  Body: string;
}
export const FaqProps = {
  Subject: {
    rules: [validation.required, validation.minMax(5, 200)],
  },
  Body: {
    rules: [validation.minMax(10, 3000)],
  },
};
