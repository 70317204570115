import React from "react";
import css from "@emotion/css";

import { Input, Form, Button, message } from "antd";

import { FormProps } from "antd/lib/form";

import { sha256 } from "js-sha256";

import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
  InfoOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { UserProps } from "../../data";
import Captcha from "../../components/Captcha";
import { register } from "../../data/api/auth";
import { Link } from "react-router-dom";
import HalfRed from "../../components/background/HalfRed";
interface IProps extends FormProps {}
interface IState {
  loading: boolean;
  recaptchaResponseKey: string;
  recaptchaValid: boolean;
  submitable: boolean;
}

class Register extends React.Component<IProps, IState> {
  public state = {
    loading: false,
    recaptchaResponseKey: "",
    recaptchaValid: false,
    submitable: false,
  };

  public recaptchaRef: any;

  public verifyCallback = (responseKey: string) => {
    this.setState({
      recaptchaResponseKey: responseKey,
      recaptchaValid: true,
    });
  };

  public render() {
    return (
      <HalfRed>
        <img css={logoCss} src="tdk.png" alt="logo" />

        <Form onFinish={this.onSubmit}>
          <Form.Item
            name="NameSurname"
            rules={UserProps.NameSurname.rules}
          >
            <Input
              placeholder="Ad Soyadı"
              size="large"
              prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item name="Username" rules={UserProps.Username.rules}>
            <Input
              placeholder="E-posta"
              type="email"
              size="large"
              prefix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item name="Mobile" rules={UserProps.Mobile.rules}>
            <Input
              placeholder="Cep Telefonu"
              size="large"
              prefix={<PhoneOutlined />}
            />
          </Form.Item>
          <Form.Item name="Field" rules={UserProps.Field.rules}>
            <Input
              placeholder="Çalışma Alanı"
              size="large"
              prefix={<InfoOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="Organization"
            rules={UserProps.Organization.rules}
          >
            <Input
              placeholder="Çalışılan Kurum"
              size="large"
              prefix={<TeamOutlined />}
            />
          </Form.Item>
          <Form.Item name="Title">
            <Input
              placeholder="Unvanı"
              size="large"
              prefix={<FileTextOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="Password"
            rules={[
              {
                required: true,
                message: "Şifrenizi giriniz",
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Şifre"
              size="large"
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="PasswordAgain"
            rules={[
              {
                required: true,
                message: "Lütfen şifre tekrarını giriniz.",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("Password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Girilen şifreler birbiriyle uyuşmuyor.",
                  );
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              size="large"
              placeholder="Şifre (Tekrar)"
              prefix={<LockOutlined />}
            />
          </Form.Item>

          <Form.Item>
            <Captcha
              captchaRef={this.recaptchaRef}
              onLoadCallback={this.onCaptchaLoaded}
              onExpiredCallback={() => this.recaptchaRef.current.reset()}
              verifyCallback={this.verifyCallback}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              loading={this.state.loading}
              style={{
                width: "100%",
                backgroundColor: "#e31d23",
                borderColor: "#c71b20",
                fontWeight: "bold",
              }}
              type="primary"
              disabled={!this.state.submitable}
            >
              Kaydet
            </Button>
          </Form.Item>
        </Form>
        <Link to="/login" style={{ float: "left", color: "white" }}>
          Zaten hesabınız var mı? Giriş yapın.
        </Link>
      </HalfRed>
    );
  }
  private onCaptchaLoaded = () => {
    this.setState({ submitable: true });
  };

  // TODO: need to change validating and update
  private onSubmit = (values: any) => {
    this.setState({ loading: true });
    values.Password = sha256(values.Password);
    values.RoleID = 2;

    delete values.PasswordAgain;

    register(values, this.state.recaptchaResponseKey)
      .then(() => {
        this.setState({ loading: false });
        message.success(
          "Başvurunuz alınmıştır. Lütfen e-posta adresinize gönderilen doğrulama bağlantısını tamamlayınız.",
          8,
        );
        window.location.hash = "/login";
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        if (err.status === 409) {
          message.error(
            "Girdiğiniz e-posta adresi ile kayıtlı bir hesap var.",
            5,
          );
        } else {
          message.error("Bir hata oluştu.", 5);
        }
      });
  };
}

export const descCss = css`
  text-align: left;
  margin-left: -14px;
`;
const logoCss = css`
  width: 100px;
  margin-bottom: 20px;
`;
export default Register;
