import { DataSource } from "./index";
import { list, create, read, update, del } from "../api/faqs";
import { Faq } from "../models/Faq";
export const FaqsDS: DataSource<Faq> = {
  list,
  create,
  read,
  update,
  del,
};

export default FaqsDS;
