import React from "react";
import {
  TeamOutlined,
  EyeOutlined,
  ToolOutlined,
  SolutionOutlined,
  ProjectOutlined,
  MailOutlined,
  AuditOutlined,
  SearchOutlined,
  CloseCircleOutlined,
  CommentOutlined,
  FilePdfOutlined,
  FileAddOutlined,
  ClusterOutlined,
  LineChartOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";

export function getMenuIcon(icon: string): JSX.Element | undefined {
  switch (icon) {
    case "AuditOutlined":
      return <AuditOutlined />;
    case "SearchOutlined":
      return <SearchOutlined />;
    case "TeamOutlined":
      return <TeamOutlined />;
    case "EyeOutlined":
      return <EyeOutlined />;
    case "ToolOutlined":
      return <ToolOutlined />;
    case "SolutionOutlined":
      return <SolutionOutlined />;
    case "ProjectOutlined":
      return <ProjectOutlined />;
    case "MailOutlined":
      return <MailOutlined />;
    case "CloseCircleOutlined":
      return <CloseCircleOutlined />;
    case "CommentOutlined":
      return <CommentOutlined />;
    case "FilePdfOutlined":
      return <FilePdfOutlined />;
    case "FileAddOutlined":
      return <FileAddOutlined />;
    case "ClusterOutlined":
      return <ClusterOutlined />;
    case "LineChartOutlined":
      return <LineChartOutlined />;
    case "FileUnknownOutlined":
      return<FileUnknownOutlined />;
    default:
      return undefined;
  }
}
