import React from "react";
import css from "@emotion/css";

import { Popover, Badge } from "antd";

import { HeaderButton } from "../HeaderButton";

import PopupContent from "./PopupContent";
import { ws } from "../../../../data/api/notifications";
import { Notification } from "../../../../data/models/Notification";

import { NotificationOutlined } from "@ant-design/icons";

let WS: WebSocket;
let INSTANCE: any;

export interface State {
  count: number;
  data: Notification[];
  visible: boolean;
}

export class NotificationsButton extends React.PureComponent<any, State> {
  public state: State = { count: 0, data: [], visible: false };
  public render() {
    return (
      <Popover
        placement="bottom"
        title="Bildirimler"
        content={
          <PopupContent
            count={this.state.count}
            data={this.state.data}
            onRead={this.onItemRead}
          />
        }
        trigger="click"
      >
        <div css={containerCss}>
          <Badge count={this.state.count}>
            <HeaderButton type="default" icon={<NotificationOutlined />} />
          </Badge>
        </div>
      </Popover>
    );
  }

  public componentDidMount() {
    if (!WS || (WS && (WS.readyState !== WS.OPEN || WS.readyState !== WS.CONNECTING))) {
      WS = ws();
      WS.addEventListener("message", (event) => {
        const json: Notification[] = JSON.parse(event.data);
        if (INSTANCE) {
          INSTANCE.appendData(json);
        }
      });
    }
    INSTANCE = this;
  }
  public appendData = (newData: Notification[]) => {
    const data = newData.concat(this.state.data);
    const count = data.length;
    this.setState({ count, data });
  };

  public onItemRead = (index: number, item: Notification) => {
    this.state.data.splice(index, 1);
    const count = this.state.data.length;
    this.setState({ count, data: this.state.data });
  };
}

const containerCss = css`
  margin-left: 10px;
  margin-top: 11px;
  float: right;
  height: 35px;
`;
