import React from "react";
import { Slide } from "./Slide";

export function Search() {
  return (
    <Slide
      icon="./carousel/search-icon"
      image="./carousel/search-image"
      title="Ara"
      detail="Basit ve kullanışlı arayüz üzerinden veri tabanında yer alan metinler içinde bulmak istediğiniz kelimeyi arama çubuğuna yazın."
    />
  );
}
