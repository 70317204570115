import React from "react";
import isEqual from "react-fast-compare";
import AsyncRoute from "../../components/AsyncRoute";

import { Switch } from "react-router-dom";
import { findInTree } from "../../utils/collections/tree";
import { Menu } from "../../data";
import { getCookie } from "../../utils/cookies";

let FILTERED_ROUTES: any[];

export function calculateRoutes(menu: Menu[]) {
  FILTERED_ROUTES = routes()
    .filter((i: Route) => findInTree(menu, i.path, "Path", "Children"))
    .map((i: Route, idx: number) => <AsyncRoute exact key={i.path} path={i.path} imp={i.imp} />);
  return Promise.resolve(FILTERED_ROUTES);
}

export const MainRouter = React.memo(
  (props: any) => {
    return (
      <Switch>
        {FILTERED_ROUTES}
        {commonRoutes()}
      </Switch>
    );
  },
  (p: any, n: any) => isEqual(p, n),
);

interface Route {
  path: string;
  imp: any;
}

function routes() {
  const routeArr: Route[] = [
    { path: "/", imp: () => import("../dashboard/Dashboard") },
    { path: "/users", imp: () => import("../users/Users") },
    { path: "/users/waiting", imp: () => import("../users/UsersWaiting") },
    { path: "/users/all", imp: () => import("../users/UsersTdk") },
    { path: "/roles", imp: () => import("../roles/Roles") },
    { path: "/menus", imp: () => import("../menus/Menus") },
    { path: "/system/api", imp: () => import("../system/Api") },
    { path: "/parameters", imp: () => import("../parameters/Parameters") },
    {
      path: "/emails/emailTemplates",
      imp: () => import("../emails/emailTemplates/EmailTemplates"),
    },
    {
      path: "/emails/outgoing",
      imp: () => import("../emails/EmailOutgoing"),
    },
    { path: "/issues", imp: () => import("../issue/Issues") },
    { path: "/issues/status", imp: () => import("../issue/IssuesTDK") },
    { path: "/issueEntry", imp: () => import("../issue/IssueEntry") },
    {
      path: "/documents/all",
      imp: () => import("../documents/Documents"),
    },
    {
      path: "/documents/new",
      imp: () => import("../documents/NewDocument"),
    },
    {
      path: "/documents/tdk",
      imp: () => import("../documents/DocumentsTdk"),
    },
    {
      path: "/documents/indexer",
      imp: () => import("../indexer/IndexerQueue"),
    },
    { path: "/documents/blacklist", imp: () => import("../blacklist/Blacklist") },
    {
      path: "/searchDocument",
      imp: () => import("../search/SearchDocument"),
    },
    {
      path: "/activityLogs",
      imp: () => import("../activityLog/ActivityLog"),
    },
    {
      path: "/faq",
      imp: () => import("../faq/Faqs"),
    },
  ];

  return routeArr;
}

function commonRoutes() {
  return [
    <AsyncRoute
      exact
      key="/"
      path="/"
      imp={() => {
        return getCookie("home") === "search"
          ? import("../search/SearchDocument")
          : import("../dashboard/Dashboard");
      }}
    />,
    <AsyncRoute exact key="/profile" path="/profile" imp={() => import("../profile/Profile")} />,
    <AsyncRoute exact key="/faqs" path="/faqs" imp={() => import("../faq/FaqsView")} />,
    <AsyncRoute
      exact
      key="/forbidden"
      path="/forbidden"
      imp={() => import("../error/Forbidden")}
    />,
    <AsyncRoute key="notfound" imp={() => import("../error/NotFound")} />,
  ];
}
