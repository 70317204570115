import css from "@emotion/css";

export const centerCss = css`
  background: rgb(60, 60, 60);
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: space-around;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(./tile.png);
`;

export const bannerCss =css`
  width:100%;
`
export const rowCss = css`
  vertical-align: middle;
  margin: 20px;
  text-align: center;
`;

export const cardCss = css`
  display: flex;
  flex: 400px 0 1;
  flex-direction: column;
  align-self: center;
  box-shadow: 0px 0px 24px #080808;
`;
