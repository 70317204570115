import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Issue extends BaseModel {
  Subject: string;
  Body: string;
  Type: number;
  IssuerID: number;
  IssuerNameSurname: number;
  WordIndexID: number;
}

export const IssueProps = {
  Subject: {
    rules: [validation.required, validation.minMax(3, 30)],
  },
  Body: {
    rules: [validation.required, validation.minMax(10, 300)],
  },
  Type: {
    rules: [validation.required],
  },
  IssuerID: {
    rules: [validation.required],
  },
  IssuerNameSurname: {
    rules: [validation.required, validation.minMax(3, 100)],
  },
};
