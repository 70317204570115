import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export interface Props extends RouteProps {
  imp: () => Promise<any>;
}


const renderLoader = () => <LoadingComponent />;

const AsyncRoute = (props: Props) => {
  const { imp, ...routeProps } = props;
  const Content = React.lazy(imp);
  const Component = () => <React.Suspense fallback={renderLoader()}>
    <Content />
  </React.Suspense>;
  return (
    <Route
      {...routeProps}
      render={(rProps: any) => <Component {...rProps} />}
    />
  );
};

export default AsyncRoute;

const LoadingComponent = React.memo(
  () => (
    <div style={{ textAlign: "center", paddingTop: 50, fontSize: 20 }}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        delay={400}
        size="large"
        tip="Sayfanız yükleniyor"
      />
    </div>
  ),
  () => true,
);
