import React from "react";
import { css } from "@emotion/core";
interface Props {
  icon: string;
  image: string;
  title: string;
  detail: string;
}
export function Slide(props: Props) {
  return <div css={carouselCss}>
    <img css={iconCss} src={props.icon + ".png"} alt={props.icon} />
    <img css={imageCss} src={props.image + "@2x.png"}  alt={props.image} />
    <div css={titleCss}>
      {props.title}
    </div>
    <div css={descCss}>
      {props.detail}
    </div>
  </div>;
}

const carouselCss = css`
  display:flex;
  flex-direction:column;
  justify-content:center;
  vertical-align:center;
  height:100%;
  padding: 0 30px;
  background-image:url("./carousel/bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
const iconCss = css`
  width:80px;
  align-self:center;
  margin-bottom:20px;
`;
const imageCss = css`
  align-self:center;
  object-fit:contain;
  max-width:805px;
`;
const titleCss = css`
  color:white;
  font-size:40px;
  text-align:center;
  margin-top: -50px;
align-self:center;
`;
const descCss = css`
  color:white;
  font-size:16px;
  text-align:center;
  max-width:600px;
  align-self:center;
  margin-bottom:60px;
`;