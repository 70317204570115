import React from "react";
import { css } from "@emotion/core";

interface Props {
  name?: string;
}
export function ProfileButton(props: Props) {
  return <a css={nameCss} href="#/profile">
    Hoş geldiniz, <b>{" " + props.name + " "}</b>
  </a>;
};

const nameCss = css`
  float:right;
  line-height:2;
  margin-top: 12px;
  padding: 5px;
  b{
    transition: all .5s ease;
  }
  b:hover{
    background-color :#cccccccc;
  }
`;