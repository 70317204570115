import { message } from "antd";

// const fetch = require("unfetch").default;

export declare const BACKEND_REST: string;
export declare const BACKEND_WS: string;
export declare const DEPLOY_ENV: string;

export const API = BACKEND_REST;
export const API_WS = BACKEND_WS;
export const DEPLOY_ENVIRONMENT = DEPLOY_ENV;

let CACHE: any = {};

export function clearCache() {
  CACHE = {};
}

export interface FetchError extends Error {
  status: number;
  text: Promise<any>;
  cause?: any;
}

const headers: Record<string, string> =
  DEPLOY_ENVIRONMENT === "development"
    ? {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    }
    : {
      "Content-Type": "application/json",
    };
export function cache(
  path: string,
  method: string,
  handle401: boolean,
  data: any,
): Promise<any> {
  const key = path + method;
  if (!CACHE[key] || !CACHE[`${key}-status`]) {
    CACHE[key] = load(path, method, handle401, data);
  }
  return CACHE[key];
}

export function load(
  path: string,
  method: string,
  handle401: boolean,
  data: any,
  api?: QApi,
): Promise<any> {
  return fetch(API + path, {
    method,
    credentials: "include",
    headers,
    body: JSON.stringify(data),
  })
    .then((response: Response) => {
      if (response.ok) {
        try {
          CACHE[`${path + method}-status`] = true;
          if (api) {
            api.totalCount = parseInt(
              response.headers.get("X-Total-Count") + "",
              10,
            );
          }
          return response.json();
        } catch (e) {
          console.error(e);
          CACHE[`${path + method}-status`] = false;
          redirectToLogin(handle401 && response.status === 401);
          redirectToForbidden(response.status === 403);
          // eslint-disable-next-line
          throw {
            status: response.status,
            text: response.json(),
            cause: e,
          } as FetchError;
        }
      } else {
        redirectToLogin(handle401 && response.status === 401);
        redirectToForbidden(response.status === 403);
        CACHE[`${path + method}-status`] = true;
        // eslint-disable-next-line
        throw {
          status: response.status,
          text: response.json(),
        } as FetchError;
      }
    })
    .catch((e: any) => {
      console.error(e);
      throw e;
    })
    .then((json: any) => json);
}

function redirectToLogin(handle: boolean = false) {
  if (handle) {
    message.config({
      maxCount: 1,
    });
    message.loading(
      "Uzun süre işlem yapmadınız. Girişe yönlendiriliyorsunuz.",
      2,
      () => {
        window.location.hash = "/login";
      },
    );
  }
}

function redirectToForbidden(handle: boolean = false) {
  if (handle) {
    window.location.hash = "/forbidden";
  }
}

export interface IReq {
  ID: string | number;
}

export interface QApi {
  sort?: string[];
  fields?: string[];
  filter?: string[];
  q?: string;
  limit?: number;
  offset?: number;
  totalCount?: number;
}
export function qapi2Url(api?: QApi): string {
  if (!api) {
    return "";
  }
  const parts: string[] = [];

  if (api.limit) {
    parts.push(`_limit=${api.limit}`);
  }
  if (api.offset) {
    parts.push(`_offset=${api.offset}`);
  }

  if (api.sort && api.sort.length > 0) {
    parts.push(`_sort=${api.sort.join(",")}`);
  }
  if (api.filter && api.filter.length > 0) {
    parts.push(`_filter=${api.filter.join(",")}`);
  }
  if (api.fields && api.fields.length > 0) {
    parts.push(`_fields=${api.fields.join(",")}`);
  }
  if (api.q && api.q.length > 0) {
    parts.push(`_q=${api.q}`);
  }
  if (parts.length > 0) {
    return `?${parts.join("&")}`;
  }
  return "";
}
