import React from "react";

import { Input, Form, Button, Alert } from "antd";
import { FormProps } from "antd/lib/form";
import { sha256 } from "js-sha256";

import { auth, logout } from "../../data/api/auth";
import { clearCache } from "../../data/api";

import {
  UserOutlined,
  LockOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import HalfRed from "../../components/background/HalfRed";
import { css } from "@emotion/core";
import { setCookie } from "../../utils/cookies";

interface Props extends FormProps {
  history: any;
  location: any;
}
interface State {
  loading: boolean;
  message?: string;
  error: boolean;
}

function Login(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState<string | undefined>(
    undefined,
  );

  React.useLayoutEffect(() => {
    const state = props.location.state;
    if (state && state.logout) {
      logout().catch((e: any) => console.error(e));
    }
  }, [props.location.state]);

  const onSubmit = React.useCallback(
    (values: any) => {
      setLoading(true);
      auth({
        username: values.username,
        password: sha256(values.password),
      })
        .then((r) => {
          setCookie("home", r.RoleID === 3 ? "search" : "dashboard");
          clearCache();
          setError(false);
          setTimeout(() => props.history.replace("/"), 1000);
        })
        .catch((err: any) => {
          let message = "Beklenmeyen bir hata ile karşılaşıldı.";
          if (err) {
            switch (err.status) {
              case 401:
                message = "Hatalı E-posta veya Şifre girdiniz.";
                break;
              case 404:
                message =
                  "Sunucuya ulaşılamıyor. Tekrar denedikten sonra giriş yapamazsanız sistem yöneticinize başvurunuz.";
                break;
              default:
                message =
                  "Beklenmedik bir hata ile karşılaşıldı. Tekrar denedikten sonra giriş yapamazsanız sistem yöneticinize başvurunuz.";
            }
          }
          setLoading(false);
          setMessage(message);
          setError(true);
        });
    },
    [props],
  );
  return (
    <HalfRed>
      <img css={logoCss} src="tdk.png" alt="logo" />
      <div css={titleCss}>Metin Veri Tabanı</div>

      <Form onFinish={onSubmit}>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Lütfen e-posta adresinizi giriniz!",
            },
          ]}
        >
          <Input
            placeholder="E-posta"
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Lütfen şifrenizi giriniz!" },
          ]}
        >
          <Input.Password
            autoComplete="off"
            type="password"
            size="large"
            placeholder="Şifre"
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            size="large"
            loading={loading}
            style={{
              width: "100%",
              letterSpacing: 1,
              backgroundColor: "#e31d23",
              borderColor: "#c71b20",
              fontWeight: "bold",
            }}
            type="primary"
            icon={<LoginOutlined />}
          >
            Giriş
          </Button>
        </Form.Item>
        {message && (
          <Alert
            message={message}
            type={error ? "error" : "success"}
            showIcon={!error}
          />
        )}
        <Link to="/register" style={{ float: "left", color: "white", fontWeight: "bold" }}>
          Kaydol
        </Link>
        <Link to="/resetpassword" style={{ float: "right", color: "white", fontWeight: "bold" }}>
          Şifremi Unuttum
        </Link>
      </Form>
    </HalfRed>
  );
}

export default React.memo(Login);

const logoCss = css`
  width: 100px;
  margin-bottom:20px;
`;
const titleCss = css`
  margin-bottom:30px;
  font-size:29px;
  text-align:center;
  font-weight:bolder;
  color:white;
  line-height:1.3;
`;