import validation from "../../utils/messages/validation";

export interface Notification {
  ID: number;
  CreatedAt: string;
  UserID: number;
  Title: string;
  Body: string;
  ReadAt?: number;
}
export const NotificationProps = {
  Title: {
    rules: [validation.required, validation.minMax(10, 100)],
  },
  Body: {
    rules: [validation.required, validation.minMax(1, 300)],
  },
};
