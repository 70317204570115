import React, { useEffect } from "react";
import Recaptcha from "react-recaptcha";
import css from "@emotion/css";

interface Props {
  captchaRef: any;
  theme?: "dark" | "light";
  onLoadCallback: () => any;
  onExpiredCallback: () => any;
  verifyCallback: (token: string) => any;
}

function Captcha(props: Props) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
    script.async = true;
    script.defer = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <div css={recaptcha}>
      <Recaptcha
        ref={props.captchaRef}
        theme={props.theme || "light"}
        sitekey="6LdTg80ZAAAAAPthL9Q3uLLXdYLvrS-XJgsRFUNS"
        render="explicit"
        onloadCallback={props.onLoadCallback}
        expiredCallback={props.onExpiredCallback}
        verifyCallback={props.verifyCallback}
      />
    </div>
  );
}

const recaptcha = css`
  @media (max-width: 480px) {
    & > #g-recaptcha {
      transform: scale(0.88);
      -webkit-transform: scal(0.88);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }
`;

export default React.memo(Captcha);
