import { cache, load, IReq, QApi, qapi2Url } from "./index";
import { Faq } from "../models/Faq";

interface IChildReq extends IReq {
  childID: number;
}

export const treeAll = (): Promise<Faq[]> =>
  load(`faqs/tree/all`, "GET", true, undefined);

export const tree = (
  req?: IReq,
  useCache: boolean = true,
): Promise<Faq> =>
  useCache
    ? cache(`faqs/tree`, "GET", true, undefined)
    : load(`faqs/tree`, "GET", true, undefined);

export const list = (req?: any, api?: QApi): Promise<Faq[]> =>
  load(`faqs${qapi2Url(api)}`, "GET", true, undefined, api);
export const create = (data: Faq): Promise<Faq> =>
  load(`faqs`, "POST", false, data);
export const read = (req: IReq): Promise<Faq> =>
  load(`faqs/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Faq): Promise<Faq> =>
  load(`faqs/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Faq> =>
  load(`faqs/${req.ID}`, "DELETE", true, undefined);

export const updateOrder = (req: IReq, order: number): Promise<string> =>
  load(`faqs/${req.ID}/order/${order}`, "PUT", true, undefined);