import { DataSource } from "./index";
import { list, create, read, update, del } from "../api/documents";
import { Document } from "../models/Document";
export const DocumentsDS: DataSource<Document> = {
  list,
  create,
  read,
  update,
  del,
};

export default DocumentsDS;
