import React from "react";
import css from "@emotion/css";

import { Input, Form, Button, message } from "antd";
import { sha256 } from "js-sha256";

import { setPassword } from "../../data/api/auth";
import { rowCss } from "../../components/publicPage/PublicPage.styles";
import { FormProps } from "antd/lib/form";

import { LockOutlined } from "@ant-design/icons";
import HalfRed from "../../components/background/HalfRed";

interface IProps extends FormProps {
  age: number;
  name: string;
  history: any;
  location: any;
  match: any;
}
interface IState {
  loading: boolean;
}

class SetPassword extends React.Component<IProps, IState> {
  public state = { loading: false };

  public render() {
    return (
      <HalfRed>
              <img css={logoCss} src="tdk.png" alt="logo" />

      <div css={rowCss}>
          <div css={descCss}>
            Yeni şifrenizi bu sayfa ile belirleyebilirsiniz. Girdiğiniz
            şifre ile şifre tekrarı aynı olmak zorundadır.
          </div>
        </div>
        <Form onFinish={this.onSubmit}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Yeni şifrenizi giriniz",
              },
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Şifre"
              size="large"
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="passwordAgain"
            rules={[
              {
                required: true,
                message: "Lütfen şifre tekrarını giriniz.",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Girilen şifreler birbiriyle uyuşmuyor.",
                  );
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              size="large"
              placeholder="Şifre (Tekrar)"
              prefix={<LockOutlined />}
            />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              loading={this.state.loading}
              style={{ width: "100%" }}
              type="primary"
            >
              Kaydet
            </Button>
          </Form.Item>
        </Form>
      </HalfRed>
    );
  }
  // TODO: need to change validating and update
  private onSubmit = (values: any) => {
    this.setState({ loading: true });

    setPassword(this.props.match.params.token, {
      password: sha256(values.password),
    })
      .then((r) => {
        message.success("Şifreniz başarı ile değiştirildi.", 5);
        window.location.hash = "/login";
      })
      .catch((fail: any) => {
        if (fail.cause.status === 401) {
          message.error(
            "Bu sayfada olmamanız gerekiyor, hatalı bir eposta ile yönlendiniz.",
            5,
          );
        }
        this.setState({ loading: false });
      });
  };
}

export const descCss = css`
  text-align: left;
  margin-left: -14px;
`;
const logoCss = css`
  width: 100px;
  margin-bottom:20px;
`;
export default SetPassword;
