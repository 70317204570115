import React from "react";
import "./App.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./pages/main/Main";
import Login from "./pages/login/Login";
import Password from "./pages/password/Password";
import ResetPassword from "./pages/password/ResetPassword";
import Register from "./pages/register/Register";
import Verify from "./pages/register/Verify";
import AsyncRoute from "./components/AsyncRoute";

const App = (props: any) => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/register"
          render={(rProps: any) => <Register {...rProps} />}
        />
        <Route
          exact
          path="/login"
          render={(rProps: any) => <Login {...rProps} />}
        />
        <Route
          exact
          path="/resetpassword"
          render={(rProps: any) => <ResetPassword {...rProps} />}
        />
        <Route
          exact
          path="/setpassword/:token"
          render={(rProps: any) => <Password {...rProps} />}
        />
        <Route
          exact
          path="/verify"
          render={(rProps: any) => <Verify {...rProps} />}
        />
        <AsyncRoute
          exact
          key="/preview/:word/:hash"
          path="/preview/:word/:hash"
          imp={() => import("./pages/search/preview/PreviewPdf")}
        />,
        <Route path="/" render={(rProps: any) => <Main {...rProps} />} />
      </Switch>
    </Router>
  );
};

export default App;
