import React from "react";
import css from "@emotion/css";
import { Layout } from "antd";

export const Content = (props: any) => (
  <Layout.Content
    css={containerCss}
    style={{
      maxHeight: "calc(100vh - 84px)",
      minHeight: "50%",
      overflowY: "auto",
      overflowX: "hidden",
      // flex: "initial",
    }}
  >
    {props.children}
  </Layout.Content>
);

const containerCss = css`
  display: block;
  background: #fff;
  box-shadow: 0px 0px 10px lightgray;
  margin: 0 16px;
  padding: 24px;
`;
