import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface Document extends BaseModel {
  Name: string;
  Author: string;
  PressYear: number;
  SourceAuthor?: string;
  SourceYear?: number;
  SourceCentury?: number;
  SourceYearType: string;
  Type: number;
  CoverPath: string;
  Cover?: any;
  ContentPath: string;
  PageCount: number;
  CreatorID: string;
  WordIndexes: string;
  IndexedAt: string;
  Log: string;
  Duration: number;
  Marc: string;
  Publisher: string;
}


export const DocumentProps = {
  Name: {
    rules: [
      validation.required,
      validation.minMax(4, 150),
    ],
  },
  Author: {
    rules: [
      validation.required,
      validation.minMax(4, 150),
    ],
  },
  PressYear: {
    rules: [
      validation.required,
    ],
  },
  Publisher: {
    rules: [
      validation.required,
      validation.minMax(4, 150),
    ],
  },
  SourceAuthor: {
    rules: [
      validation.minMax(4, 150),
    ],
  },
  SourceYearType: {
    rules: [
      validation.required,
    ],
  },
  Type: {
    rules: [
      validation.required,
    ],
  },
  Cover: {
    rules: [
      validation.required,
    ],
  },
  Content: {
    rules: [
      validation.required,
    ],
  },
  Marc: {
    rules: [
      validation.max(1000),
    ],
  },
};


export const DocumentTypes = [
  { ID: 1, Name: "Dil bilgisi" },
  { ID: 2, Name: "Sözlük" },
  { ID: 3, Name: "Metin" },
  { ID: 4, Name: "Araştırma/İnceleme" },
];