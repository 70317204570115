import React from "react";

import { FormProps } from "antd/lib/form";
import { rowCss } from "../../components/publicPage/PublicPage.styles";
import { css } from "@emotion/core";
import HalfRed from "../../components/background/HalfRed";

interface IProps extends FormProps {
  age: number;
  name: string;
  history: any;
  location: any;
  match: any;
}
interface IState {
  loading: boolean;
}

class Verify extends React.Component<IProps, IState> {
  public state = { loading: false };

  public render() {
    return (
      <HalfRed>
        <img css={logoCss} src="tdk.png" alt="logo" />

        <div css={rowCss}>
          <div css={descCss}>
            Üyelik talebiniz başarıyla oluşturulmuştur. Sistem
            yöneticisinin onayından sonra üyeliğiniz etkinleştirilecek ve
            size bilgi verilecektir.
          </div>
        </div>
      </HalfRed>
    );
  }
}

export const descCss = css`
  text-align: center;
  margin-left: -14px;
  font-size: 22px;
  font-weight: bold;
  color: white;
`;
const logoCss = css`
  width: 100px;
  margin-bottom: 20px;
`;
export default Verify;
