import css from "@emotion/css";

export const centerCss = css`
  background: rgb(60, 60, 60);
  height: 100vh;
  display: flex;
  align-content: space-around;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(./tile.png);
  padding: 20px;
`;

export const aykLogoCss = css`
  position: fixed;
  bottom: 10px;
  width: 80px;
  right: 10px;
`;
export const tdkLogoCss = css`
  position: fixed;
  bottom: 10px;
  width: 80px;
  right: 110px;
`;