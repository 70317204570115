import validation from "../../utils/messages/validation";
import BaseModel from "./BaseModel";

export interface User extends BaseModel {
  Username: string;
  Password: string;
  IsActive: boolean;
  FailCount: number;
  LastLogin: string;
  RoleID: number;
  NameSurname: string;
  Verified: boolean;
  Accepted: boolean;
  Organization: string;
  Title: string;
  Mobile: string;
  Field: string;
}

export const UserProps = {
  Username: {
    rules: [
      validation.required,
      validation.minMax(6, 50),
      //validation.type("email"),
    ],
  },
  RoleID: {
    rules: [validation.required],
  },
  NameSurname: {
    rules: [validation.max(100), validation.required],
  },
  Organization: {
    rules: [validation.max(100)],
  },
  Title: {
    rules: [validation.max(50)],
  },
  Mobile: {
    rules: [validation.required, validation.minMax(11, 15)],
  },
  Field: {
    rules: [validation.required, validation.minMax(5, 50)],
  },
};
