import { load, cache, API_WS } from "./index";
import { Notification } from "../models/Notification";

export const ws = (req?: any): WebSocket =>new WebSocket(`${API_WS}notifications/ws`);

export const list = (req?: any): Promise<Notification[]> =>
  cache(`notifications`, "GET", true, undefined);

export const read = (req?: any): Promise<Notification> =>
  load(`notifications/${req.ID}/read`, "PUT", true, undefined);
